<template>
  <div class="terminal-min-height">
    <portal to="header">
      <terminal-header
          :title="$t('Assign')"
          @barcode="handleBarcode"
      />
    </portal>

    <div v-if="action === 'scanBasket'">
      <assistant-object :data="assistantObject"/>
    </div>

    <div v-if="place" :style="`height: ${terminalContainerHeight}px;`">
      <div v-if="action === 'place'" class="q-pa-xs q-mt-sm border-top--bold" style="max-width: calc(100vw - 16px);">
        <place-object
            :data="place"
            :type="place.type"
            class="q-my-sm"
        />

        <q-card-section class="q-pa-none border">
          <q-table
              row-key="id"
              :rows="items"
              :columns="columns"
              v-model:pagination="pagination"
              :loading="isLoading"
              :filter="filter"
              :rows-per-page-options="[]"
              table-header-class="d-none"
              virtual-scroll
              binary-state-sort
              flat
          >
            <template v-slot:loading>
              <q-inner-loading
                  showing
                  color="primary"
              />
            </template>

            <template v-slot:body="props">
              <q-tr
                  :props="props"
                  class="clickable"
                  @click="openPlace(props.row._embedded.productOffer)"
              >
                <q-td
                    key="image"
                    :props="props"
                    auto-width
                >
                  <label :class="`q-py-xs q-px-sm rounded bg-${itemStates[props.row.state]}`">
                    {{ $t(stateName[props.row.state] || props.row.state) }}
                  </label>
                </q-td>

                <q-td>
                  <h5 class="text-center">{{ props.row.count }}</h5>
                </q-td>

                <q-td
                    key="image"
                    :props="props"
                    auto-width
                >
                  <img
                      :src="props.row._embedded?.productOffer?.image ? props.row._embedded?.productOffer?.image : fallbackImage"
                      style="width: 80px; height: 70px; object-fit: contain;"
                      @error="onImageLoadFailure"
                  >
                </q-td>

                <q-td
                    key="details"
                    :props="props"
                    style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
                >
                  <div class="text-subtitle1">
                    <q-badge>{{ props.row._embedded?.productOffer?.type }}</q-badge>
                    {{ `${props.row._embedded?.productOffer?.name} (${props.row._embedded?.productOffer?.id})` }}
                  </div>

                  <div class="text-caption">
                    <strong v-if="props.row._embedded?.productOffer?.sku">{{
                        props.row._embedded?.productOffer?.sku
                      }}</strong><br v-if="props.row._embedded?.productOffer?.sku"/>
                    {{ props.row._embedded?.productOffer?.barcodes?.join(', ') }}
                  </div>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </q-card-section>
      </div>
      <div v-if="action === 'offer'">
        <product-section :data="offer" :options="{image: {width:245, height: 245}}"/>

        <place-object
            :data="place"
            :type="place.type"
            class="q-my-sm"
        />

        <quantity-section
            :data="quantity"
            :options="quantityOptions"
            @click="handleCounterSubmit"
        />

        <counter-section :disable-additional-action="true" :data="counterValue" :options="counterOptions" @click="handleRequestData"/>

        <div class="q-pa-xs q-mt-sm border-top--bold" style="max-width: calc(100vw - 16px);">
          <q-card-section class="q-pa-none border">
            <q-table
                row-key="id"
                :rows="places"
                :columns="columns"
                v-model:pagination="pagination"
                :loading="isLoading"
                :filter="filter"
                :rows-per-page-options="[]"
                table-header-class="d-none"
                virtual-scroll
                hide-bottom
                binary-state-sort
                flat
            >
              <template v-slot:loading>
                <q-inner-loading
                    showing
                    color="primary"
                />
              </template>

              <template v-slot:body="props">
                <q-tr
                    :props="props"
                >
                  <q-td
                      key="image"
                      :props="props"
                      auto-width
                  >
                    <label :class="`q-py-xs q-px-sm rounded bg-${itemStates[props.row.state]}`">
                      {{ $t(stateName[props.row.state] || props.row.state) }}
                    </label>
                  </q-td>

                  <q-td>
                    <h5 class="text-center">{{ props.row.count }}</h5>
                  </q-td>

                  <q-td
                      key="details"
                      :props="props"
                      style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
                  >
                    <div class="text-subtitle1 items-center">
                    <span v-if="props.row._embedded?.place">
                      <place-object
                          :data="props.row._embedded.place"
                          :type="props.row._embedded.place.type"
                          class="q-mb-sm"
                      />
                    </span>

                    </div>

                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </q-card-section>
        </div>
      </div>
    </div>

    <request-data-modal
        ref="requestDataModal"
        @submit="handleRequestData"
        @close="$emit('unblock')"
    />

  </div>
  <portal to="settings">
    <tiles :schema="tilesSchema"/>
  </portal>

  <confirm-modal ref="confirmModal" />
  <location-logs ref="logs"/>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PlaceItemsChange',
  data () {
    return {
      action: 'scanBasket',
      sku: null,
      offer: null,
      place: null,
      items: [],
      places: [],
      counterValue: 0,
      totalCount: 0,
      isDoneActive: false,
      isLoading: false,
      fallbackImage: 'assets/img/fallback-image/package.png',
      assistantObject: {
        title: 'Please scan',
        caption: 'Location',
        captionColor: 'amber',
        image: 'scanBasketPlace'
      },
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      filter: '',
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'center'
        },
        {
          label: this.$t('Details'),
          name: 'details',
          align: 'left'
        }
      ],
      options: {},
      itemStates: {
        new: 'grey-3',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green text-white',
        normal: 'success',
        expected: 'green text-white',
        missing: 'grey'
      },
      stateName: {
        normal: 'On hand',
        blocked: 'Blocked',
        booked: 'Booked',
        new: 'Received',
        defected: 'Defected',
      },
      counterOptions: {
        allowNegative: true,
        allowNoLimit: true,
        allowAdditionalAction: {
          label: this.$t(`Move`)
        }
      },
      searchOptions: {
        filter: [
          {
            type: 'eq',
            alias: 'i',
            field: 'place',
            value: this.place
          },
          {
            type: 'gte',
            field: 'items',
            value: 1
          }
        ],
      },
      selectedQuantity: 0
    }
  },
  watch: {
    offer: {
      handler (value, oldValue) {
        this.counterValue = 0
        this.totalCount = 0
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters([
      'terminalSettings',
      'terminalContainerHeight'
    ]),
    placesToMove () {
      if(this.places && this.place) {
        let places = this.places.filter(e=> e._embedded?.place?.id && this.place.id !== e._embedded.place.id )
        return places
      }
      return []
    },
    tilesSchema () {
      const options = []

      if (this.isDoneActive) {
        options.push({
          id: 1,
          label: this.$t('Back'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            if (this.action === 'offer') {
              this.action = 'place'
              this.offer = null
            } else if (this.action === 'place' && this.place) {
              this.place = null
              this.offer = null
              this.items = null
              this.updateTerminalSettings({ catchAll: true })
              this.action = 'scanBasket'
            }
          }
        })
        options.push({
          id: 2,
          label: this.$t('Start new cycle'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            this.handleReset()
          }
        })
        options.push({
          id: 3,
          label: this.$t('Complete'),
          icon: 'done',
          value: true,
          onChanged: () => {

            const description = this.$t("Do you want to start new cycle?")
            this.$refs.confirmModal.show({ description })
                .then(isOk => {
                  if (!isOk) {
                    this.$router.go()
                    return
                  }
                  this.handleReset()
                })
          }
        })
      } else {
        options.push({
          id: 1,
          label: this.$t('Back'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            if (this.action === 'offer') {
              this.action = 'place'
              this.offer = null
            } else if (this.action === 'place' && this.place) {
              this.place = null
              this.offer = null
              this.items = null
              this.updateTerminalSettings({ catchAll: true })
              this.action = 'scanBasket'
            }
          }
        })

        options.push({
          id: 2,
          label: this.$t('Start new cycle'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            this.handleReset()
          }
        })
        if(this.offer) {
          options.push({
            id: 5,
            label: this.$t('Locations'),
            icon: 'history',
            value: true,
            onChanged: () => {
              this.$refs.logs.open(this.offer)
            }
          })
        }
      }

      return options
    },
    totalNormalQuantity () {
      if (!this.items) {
        return 0
      }

      const foundPlace = this.items.find(place => place._embedded.productOffer.id === this.offer.id)

      return foundPlace.count
    },
    quantity () {
      const counterObj = {
        barcode: '',
        max: 0,
        current: 0
      }

      if (this.offer) {
        counterObj.max = this.totalNormalQuantity
        counterObj.current = this.totalCount

        if (counterObj.current === 0) {
          counterObj.current = this.totalNormalQuantity
        }
      }

      return counterObj
    },
    quantityOptions () {
      return {
        maxDescription: 'tap to edit',
        max: 'Available',
        current: 'Result'
      }
    },
  },
  methods: {
    ...mapMutations([
      'addNotification',
      'addErrorNotification',
      'updateTerminalSettings'
    ]),
    handleReset () {
      this.isDoneActive = false
      this.offer = null
      this.place = null
      this.items = []
      this.updateTerminalSettings({ catchAll: true })
      this.action = 'scanBasket'
    },
    onFocus () {
      this.isFocused = true

      this.updateTerminalSettings({ catchAll: false })
    },
    onFocusOut () {
      this.isFocused = false

      this.updateTerminalSettings({ catchAll: true })
    },
    openPlace (offer) {
      this.action = 'offer'
      this.updateTerminalSettings({ catchAll: false })
      this.offer = offer
      this.searchProductsSelected()
    },
    searchProductsSelected () {
      if (!this.offer) {
        return null
      }


      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'productOffer', value: this.offer.id },
          { type: 'in', field: 'state', values: ['new', 'normal'] }
        ],
        group: [
          { field: 'productOffer', alias: 'i' },
          { field: 'place', alias: 'i' },
          { field: 'state', alias: 'i' }
        ]
      }

      return this.$service.storageItemEntity.getAll(query).then((places) => {
        this.places = places.items
      })
    },
    handlePlaceRequest (event) {
      this.updateTerminalSettings({ catchAll: false })

      this.selectedQuantity = event.data.value
      let places = this.placesToMove
      this.$refs.requestDataModal.open({
        data: [
          {
            'type': 'Laminas\\Form\\Element\\Text',
            'name': 'targetPlace',
            'options': {
              'label': 'Scan target location',
              'required': true
            }
          }
        ]
      },places,this.columns,this.pagination,this.itemStates,this.stateName)
    },
    handleCounterSubmit () {
      this.updateTerminalSettings({ catchAll: false })

      this.$refs.requestDataModal.open({
        data: [
          {
            'type': 'Laminas\\Form\\Element\\Number',
            'name': 'quantity',
            'options': {
              'label': 'Write exact quantity',
              'required': true
            }
          }
        ]
      })
    },
    handleRequestData (event) {
      if (event.event === 'counter.submit') {
        let cnt = event.data.value
        this.$service.storageItemEntity.patchBatch({}, {
          per_page: cnt,
          filter: [
            {
              field: 'state',
              type: 'eq',
              value: 'normal',
            },
            {
              field: 'place',
              type: 'eq',
              value: this.place.id,
            },
            {
              field: 'productOffer',
              type: 'eq',
              value: this.offer.id,
            }
          ]
        }).then(() => {
          if (this.totalCount) {
            this.totalCount = this.totalCount + parseInt(cnt)
          } else {
            this.totalCount = this.quantity.max + parseInt(cnt)
          }
          this.isDoneActive = true
        })
      } else if (event.event === 'counter.additional.submit') {
        return this.handlePlaceRequest(event)
      } else if (typeof event.quantity !== 'undefined' && !isNaN(event.quantity)) {
        let cnt = event.quantity
        this.$service.storageItemEntity.putBatch({}, {
          per_page: cnt,
          filter: [
            {
              field: 'state',
              type: 'eq',
              value: 'normal',
            },
            {
              field: 'place',
              type: 'eq',
              value: this.place.id,
            },
            {
              field: 'productOffer',
              type: 'eq',
              value: this.offer.id,
            }
          ]
        }).then(() => {
          this.totalCount = cnt
          this.isDoneActive = true
        })
      } else if (event.targetPlace) {
        let cnt = this.selectedQuantity

        const match = event.targetPlace.match(/\d+/)
        const numericPart = match ? match[0] : null

        this.$service.storageItemEntity.patchBatch([{
          place: numericPart
        }], {
          per_page: cnt,
          filter: [
            {
              field: 'state',
              type: 'eq',
              value: 'normal',
            },
            {
              field: 'place',
              type: 'eq',
              value: this.place.id,
            },
            {
              field: 'productOffer',
              type: 'eq',
              value: this.offer.id,
            }
          ]
        }).then(() => {
          this.addNotification(`Item was moved.`)
          if (this.totalCount) {
            this.totalCount = this.totalCount - parseInt(cnt)
          } else {
            this.totalCount = this.quantity.max - parseInt(cnt)
          }
          this.selectedQuantity = 0
          this.isDoneActive = true

          return
        })
      }
    },
    handleFinish () {
      this.$emit('finish')
    },
    loadPlace (placeId) {
      return this.$service.storagePlace.get(placeId)
          .then(place => {
            this.place = place
            const query = {
              per_page: 25,
              page: 1,
              filter: [
                { type: 'eq', field: 'place', value: place.id },
                { type: 'eq', field: 'state', value: 'normal' }
              ],
              group: [
                { field: 'productOffer', alias: 'i' },
                { field: 'place', alias: 'i' },
                { field: 'state', alias: 'i' }
              ]
            }

            return this.$service.storageItemEntity.getAll(query).then(({ items }) => {
              if (!items || items.length === 0) {
                this.action = 'scanBasket'
                this.addErrorNotification(`No items found on this place.`)
                this.place = null
              } else {
                this.items = items
                this.action = 'place'
              }
            })
          })
    },
    handleBarcode (barcode) {
      if (this.action === 'scanBasket') {
        const isOpened = this.$utils.storeUtils.getLockedPlaceEvent(barcode.value)

        if (isOpened) {
          this.addErrorNotification(`Someone already work on this place!`)
          return Promise.resolve(null)
        }

        return this.loadPlace(barcode.value)
      }

      this.barcode = barcode
    }
  }
}
</script>
